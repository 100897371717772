import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AwareSecurityService } from '@appbolaget/aware-security';
import { take } from 'rxjs/operators';

export function godGuard(): CanActivateFn {
    return () => {
        const ass = inject(AwareSecurityService);
        return ass.isGod().pipe(take(1));
    };
}
