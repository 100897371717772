import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export function participantGuard(): CanActivateFn {
    return () => {
        const ass = inject(AwareSecurityService);
        const router = inject(Router);

        return ass.isGod().pipe(
            mergeMap((isGod) => {
                return combineLatest([of(isGod), ass.isGranted('role.participant'), ass.isGranted('role.nonparticipant')]);
            }),
            map(([isGod, isParticipant, isNonParticipant]) => {
                if (isGod || isNonParticipant) {
                    return true;
                }

                if (isParticipant) {
                    router.navigate(['/app/me/dashboard']);
                }

                return !isParticipant;
            }),
        );
    };
}
