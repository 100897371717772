import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { tap } from 'rxjs/operators';

export function adminGuard(): CanActivateFn {
    return () => {
        const ass = inject(AwareSecurityService);
        const router = inject(Router);

        return ass.isGranted('unit.create').pipe(
            tap((hasUnitCreate) => {
                if (!hasUnitCreate) {
                    router.navigate(['/app/me']);
                }
            }),
        );
    };
}
